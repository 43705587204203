<template>
  <section>
    <form v-form class="form-inline" v-if="examinee">
      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered" v-table:info v-cols="[25, 75]">
            <tbody>
              <tr>
                <th>会員番号</th>
                <td>{{examinee.examineeNumber}}</td>
              </tr>
              <tr>
                <th>受検者名</th>
                <td>{{examinee.familyName}} {{examinee.givenName}}</td>
              </tr>
              <tr>
                <th>受検者名ローマ字</th>
                <td>
                  <span v-if="examinee.familyNameEn || examinee.givenNameEn">{{examinee.familyNameEn}} {{examinee.givenNameEn}}</span>
                  <span v-else>未登録</span>
                </td>
              </tr>
              <tr>
                <th>性別</th>
                <td>{{examinee.sex ? examinee.sex.name : ""}}</td>
              </tr>
              <tr>
                <th>生年月日</th>
                <td>{{examinee.dateOfBirth | date('YYYY/MM/DD')}}</td>
              </tr>
              <tr>
                <th>メールアドレス</th>
                <td>{{examinee.email || '未登録'}}</td>
              </tr>
              <tr>
                <th>受検級</th>
                <td>
                  <span v-if="editExamGrade">
                    <input-select v-model="examGradeCode" :null-option="true"
                                  :options="examGrades"
                                  :opt-to-value="x => x"
                                  :opt-to-input-value="t => t.code"
                                  :opt-to-label="t => t.name"
                                  :opt-is-selected="t => t.code == examGradeCode"/>
                    <button v-button:primary class="ml-1" @click="updateExamGrade">保存する</button>
                  </span>
                  <span v-else>
                    {{examinee.examGradeName}}
                    <button v-if="!examTerm.ended && !examinee.startTime && !examTerm.settingDeadlinePassed" class="ml-1" v-button:default @click="editExamGrade = true">変更する</button>
                  </span>
                </td>
              </tr>
              <tr>
                <th>試験開始日時</th>
                <td>{{examinee.startTime | timestamp}}</td>
              </tr>
              <tr>
                <th>試験終了日時</th>
                <td>{{examinee.endTime | timestamp}}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button v-button:close @click="close"/>
            <button v-if="examinee.startTime && !examTerm.ended" v-button:delete @click="reset">受検状態をリセットする</button>
            <button v-if="examinee.locked" v-button:primary @click="unlockAccount">アカウントロック解除</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import {Service} from "../_resource";
import {announce} from "@/calico-vue/service";

export default {
  data() {
    return {
      examinee: null,
      editExamGrade: false,
      examGrades: [],
      examGradeCode: null
    };
  },
  props: [
      "siteId",
      "examineeId",
      "examTerm"
  ],
  mounted() {
    Service.getExaminee(this.siteId, this.examineeId).then(examinee => {
      this.examinee = examinee;
      this.examGradeCode = examinee.examGradeCode;
    });
    Service.getGrades().then(grades => {
      this.examGrades = grades.filter(g => g.code != '1');
    });
  },
  methods: {
    close() {
      this.editExamGrade = false;
      this.$emit('close', false);
    },
    save() {
      this.$emit('save');
    },
    reset() {
      if (!confirm('受検状態をリセットしてもよろしいですか？')) return;
      Service.resetExam(this.siteId, this.examineeId).then(() => {
        announce.saved({title: '受検状態をリセットしました。'});
        this.$emit('close', true);
      });
    },
    updateExamGrade() {
      Service.updateExamGrade(this.siteId, this.examineeId, this.examGradeCode).then(() => {
        announce.saved();
        this.$emit('close', true);
      }).catch(e => this.error = e);
    },
    unlockAccount() {
      Service.unlockAccount(this.siteId, this.examineeId).then(() => {
        announce.saved({title: 'アカウントロックを解除しました。'});
        this.$emit('close', true);
      })
    }
  }
};
</script>
